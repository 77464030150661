import { useEffect, useRef } from "react";
import "../css/carousel.css";

const Carousel = (props) => {
  const carouselRef = useRef(null);
  const animationRef = useRef(null);
  const isHoveringRef = useRef(false);
  const scrollDeltaRef = useRef(0); // acumulador decimal
  const speed = 0.21; // ahora sí puede ser muy pequeño
  const manualScrollAmount = 300;

  useEffect(() => {
    const carousel = carouselRef.current;

    const originalContent = carousel.querySelector(".carousel-content");
    const clone = originalContent.cloneNode(true);
    clone.classList.add("clone");
    carousel.appendChild(clone);

    const animate = () => {
      if (!isHoveringRef.current) {
        scrollDeltaRef.current += speed;

        if (scrollDeltaRef.current >= 1) {
          const move = Math.floor(scrollDeltaRef.current);
          carousel.scrollLeft += move;
          scrollDeltaRef.current -= move;
        }

        if (carousel.scrollLeft >= originalContent.scrollWidth) {
          carousel.scrollLeft = 0;
        }
      }

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  const handleLeft = () => {
    carouselRef.current.scrollLeft -= manualScrollAmount;
  };

  const handleRight = () => {
    carouselRef.current.scrollLeft += manualScrollAmount;
  };

  const handleMouseEnter = () => {
    isHoveringRef.current = true;
  };

  const handleMouseLeave = () => {
    isHoveringRef.current = false;
  };

  return (
    <div className="carousel-container">
      <div className="gallery-indicators-carousel">
        <span className="material-icons" onClick={handleLeft}>
          arrow_back
        </span>
        <span className="material-icons" onClick={handleRight}>
          arrow_forward
        </span>
      </div>
      <div
        className="wrapper-carousel layout9"
        ref={carouselRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleMouseEnter}
        onTouchEnd={handleMouseLeave}
      >
        <div className="carousel-content">{props.content}</div>
      </div>
    </div>
  );
};

export default Carousel;
