import { useState } from "react";
import { NavLink } from "react-router-dom";
import QuickItems from "./QuickItems";
import "../css/menu.css";
import NewMenu from "./NewMenu";

function Menu() {
  const [statemenu, setStateMenu] = useState();
  const [state, setState] = useState(false);

  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = (index) => {
    setOpenMenu(openMenu === index ? null : index);
  };

  return (
    <>
      <span
        className="material-icons menu-title"
        onClick={(e) => setState(true)}
      >
        menu
      </span>

      {state && (
        <div className="wrapper-menu">
          <span
            className="material-icons menu-close"
            onClick={(e) => setState(false)}
          >
            close
          </span>

          <div className="section-menu layout8">
            <div className="items-menu">
              <ul className="menu-list">
                {/* Oferta Académica */}
                <li>
                  <button
                    className="title-options"
                    onClick={() => toggleMenu(0)}
                  >
                    Oferta Académica{" "}
                    <div className={openMenu === 0 ? "rotated" : ""} />
                  </button>
                  {openMenu === 0 && (
                    <ul className="submenu">
                      <li>
                        {" "}
                        <NavLink
                          to="/en-linea"
                          onClick={(e) => setState(false)}
                        >
                          Carreras a distancia y en línea
                        </NavLink>
                      </li>
                      <li>
                        {" "}
                        <NavLink
                          to="/presencial"
                          onClick={(e) => setState(false)}
                        >
                          Carreras presencial
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/tecnologias"
                          onClick={(e) => setState(false)}
                        >
                          Carreras tecnológicas
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/posgrados"
                          onClick={(e) => setState(false)}
                        >
                          Posgrados
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/edes" onClick={(e) => setState(false)}>
                          EDES
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/formacion-permanente"
                          onClick={(e) => setState(false)}
                        >
                          Formación permanente
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>

                {/* Información para Alumnos */}
                <li>
                  <button
                    className="title-options"
                    onClick={() => toggleMenu(1)}
                  >
                    Información para Alumnos{" "}
                    <div className={openMenu === 1 ? "rotated" : ""} />
                  </button>
                  {openMenu === 1 && (
                    <ul className="submenu">
                      <li>
                        <a href="/competencias">
                          Cursos de Competencias Específicas
                        </a>
                      </li>
                      <li>
                        <a href="/segunda-lengua">Segunda Lengua</a>
                      </li>
                      <li>
                        <a href="/acompanamiento-integral">
                          Acompañamiento integral
                        </a>
                      </li>
                    </ul>
                  )}
                </li>

                {/* Validación de título */}
                <li>
                  <NavLink
                    to="/validacion-titulo"
                    className="title-options"
                    onClick={(e) => setState(false)}
                  >
                    Validación de título EE. UU.
                  </NavLink>
                </li>

                {/* Acerca de */}
                <li>
                  <button
                    className="title-options"
                    onClick={() => toggleMenu(2)}
                  >
                    Acerca de
                    <div className={openMenu === 2 ? "rotated" : ""} />
                  </button>
                  {openMenu === 2 && (
                    <ul className="submenu">
                      <li>
                        <NavLink
                          to="/acercade"
                          onClick={(e) => setState(false)}
                        >
                          Acerca de nosotros
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/gobernanza"
                          onClick={(e) => setState(false)}
                        >
                          Gobernanza
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/transparencia"
                          onClick={(e) => setState(false)}
                        >
                          Transparencia
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/acercade"
                          onClick={(e) => setState(false)}
                        >
                          Fernando Rielo
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/acercade"
                          onClick={(e) => setState(false)}
                        >
                          Manual de Imagen
                        </NavLink>
                      </li>
                      <li>
                        {" "}
                        <a
                          className="intranet"
                          href="https://utpl.hiringroom.com/jobs"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Trabaja con nosotros
                        </a>
                      </li>
                    </ul>
                  )}
                </li>

                {/* Explora además */}
                <li>
                  <button
                    className="title-options"
                    onClick={() => toggleMenu(3)}
                  >
                    Explora además{" "}
                    <div className={openMenu === 3 ? "rotated" : ""} />
                  </button>
                  {openMenu === 3 && (
                    <ul className="submenu">
                      <li>
                        {" "}
                        <NavLink to="/alumni" onClick={(e) => setState(false)}>
                          UTPL Alumni
                        </NavLink>
                      </li>
                      <li>
                        {" "}
                        <NavLink
                          to="/parque-cientifico"
                          onClick={(e) => setState(false)}
                        >
                          Parque Científico
                        </NavLink>
                      </li>
                      <li>
                        <a
                          href="https://biblioteca.utpl.edu.ec/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Biblioteca
                        </a>{" "}
                      </li>

                      <li>
                        <a
                          href="https://ilfam.utpl.edu.ec/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          ILFAM
                        </a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://utpl.edu.ec/internacional"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Internacional
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://investigacion.utpl.edu.ec/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Investigación
                        </a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://vinculacion.utpl.edu.ec/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Vinculación
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://utpl.edu.ec/misiones"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Misiones Universitarias
                        </a>{" "}
                      </li>
                      <li>
                        <a
                          href="https://eventos.utpl.edu.ec/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Eventos
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://noticias.utpl.edu.ec"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Noticias
                        </a>
                      </li>
                    </ul>
                  )}
                </li>

                {/* Contacto */}
                <li>
                  <NavLink
                    to="/contacto"
                    className="title-options"
                    onClick={(e) => setState(false)}
                  >
                    {" "}
                    Contacto
                  </NavLink>
                </li>
              </ul>

              <div className="options-menu">
                {statemenu === 2 && (
                  <div>
                    <NavLink to="/acercade" onClick={(e) => setState(false)}>
                      Acerca de
                      <span class="material-icons">chevron_right</span>
                    </NavLink>
                    <NavLink to="/gobernanza" onClick={(e) => setState(false)}>
                      Gobernanza
                      <span class="material-icons">chevron_right</span>
                    </NavLink>
                    <NavLink to="/imagen" onClick={(e) => setState(false)}>
                      Imagen
                      <span class="material-icons">chevron_right</span>
                    </NavLink>

                    <a
                      className="intranet"
                      href="https://utpl.hiringroom.com/jobs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Trabaja con nosotros
                      <span class="material-icons">chevron_right</span>
                    </a>
                  </div>
                )}
              </div>
            </div>

            {/* 
            <div className="form">
              <div className="wrapper-blockformutpl ">
                <div className="layout8">
                  <h3>Solicita información</h3>
                  <div className="formutpl">
                    <HubspotForm
                      portalId="3056987"
                      formId="09e4a84a-50f8-45d6-a81f-8c4eab9a82be"
                      css=""
                    />
                  </div>
                </div>
              </div>
            </div>*/}
          </div>

          <QuickItems></QuickItems>
        </div>
      )}
    </>
  );
}

export default Menu;
