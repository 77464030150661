import DataFetcherTemplate from "../../hooks/fetchTemplate";
import BannerGeneral from "../../components/BannerGeneral";
import ModalFixed from "../../components/ModalFixed";
import BlockBackgroundImage from "../../components/BlockBackgroundImage";
import BlockImageCabecera from "../../components/BlockImageCabecera";
import Notification from "../../components/Notification";
import Breadcumbs from "../../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import ConectUs from "../../components/ConectUs";
import TecnologiasOffer from "../../components/TecnologiasOffer";
import ImageGallery from "../../components/ImageGallery";
import FormacionPermanenteOffer from "../../components/FormacionPermanenteOffer";
import BlockLeftImage from "../../components/BlockLeftImage";
import BlockRightImage from "../../components/BlockRightImage";
import Carousel from "../../components/Carousel";

function FormacionPermanente() {
  const DataDisplay = ({ data }) => (
    <>
      <div>
        {data.map((item, index) => (
          <div>
            <BlockImageCabecera
              src={item.field_portada}
              title={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_texto_portada,
                    }}
                  />
                </>
              }
            ></BlockImageCabecera>

            <Breadcumbs
              content={
                <>
                  <NavLink to="/formacion-permanente">
                    Formación Permanente
                  </NavLink>
                </>
              }
            ></Breadcumbs>

            <FormacionPermanenteOffer></FormacionPermanenteOffer>
            <BlockBackgroundImage
              src={item.field_imagen2}
              content={
                <div
                  className="layout8"
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque2,
                  }}
                />
              }
            ></BlockBackgroundImage>
            <BlockRightImage
              src={item.field_imagen3}
              content={
                <div
                  className="layout8"
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque3,
                  }}
                />
              }
            ></BlockRightImage>
            <div className="margin">
              <center>
                <div
                  className="layout6"
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque1,
                  }}
                />
              </center>
              <div className="flex icon150 layout6 margin">
                {item.field_gallery1.map((url, index) => (
                  <div key={index}>
                    <img src={url} alt={`Imagen ${index + 1}`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/oferta/servicio-formacionpermanente.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad de estudiantes de formación permanente."
        instagram="https://www.instagram.com/formacionpermanenteutpl/"
        facebook="https://www.facebook.com/formacionpermanenteutpl"
        x="https://twitter.com/PermanenteUtpl"
        youtube="https://www.youtube.com/channel/UCzP4vqpQ-u_FiKNi8Dv2vVw"
        tiktok="https://tiktok.com/@forpermanenteutpl"
        flickr="https://www.flickr.com/photos/194885809@N04/"
        linkedin="https://www.linkedin.com/company/educaci%C3%B3n-continua-utpl/posts/?feedView=all"
      ></ConectUs>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default FormacionPermanente;
